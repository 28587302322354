'use client';

import React, { ComponentProps, HTMLAttributes, useState } from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import { cn } from 'tailwind-config';

import { NewBoxButton } from '../NewBoxButton';
import { NewIcon, NewIconType } from '../NewIcon';

// inputVariants는 type에 따른 스타일만 정의합니다.
const inputVariants = cva('', {
  variants: {
    type: {
      line: 'pb-6 border-solid border-b-[1.6px]',
      box: 'pr-16 bg-new-gray-50 rounded-[10px] border-2',
    },
  },
  defaultVariants: {
    type: 'line',
  },
});

interface InputProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof inputVariants> {
  label: string;
  inputType: React.HTMLInputTypeAttribute;
  placeholder: string;
  placeholderIcon?: NewIconType;
  inputValue: string;
  setInputValue: (arg: string) => void;
  helperText?: string;
  buttonOption?: ComponentProps<typeof NewBoxButton>;
  onInputChange: (input: string) => void;
  isError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      placeholder,
      placeholderIcon,
      inputValue,
      setInputValue,
      helperText,
      buttonOption,
      onInputChange,
      inputType = 'text',
      isError = false,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    return (
      <div className={cn('flex w-full flex-col', className)}>
        <p className="text-new-gray-600 text-new-Caption1-bold pb-8">{label}</p>
        <article
          className={cn(
            inputVariants({ type }),
            isError
              ? 'border-new-DTRed-400'
              : isFocused
              ? 'border-new-gray-900'
              : 'border-transparent',
            'flex items-center justify-between gap-16',
          )}
        >
          <input
            ref={ref}
            type={inputType}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => onInputChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={`${
              type === 'box' ? 'bg-new-gray-50 px-16 py-[12.5px]' : ''
            } placeholder:text-new-gray-400 text-new-Body1-bold placeholder:text-new-Body1-medium w-full rounded-[10px] outline-none`}
          />
          {buttonOption ? (
            <NewBoxButton
              size="small"
              state={!inputValue ? 'inactive' : 'active'}
              className="shrink-0"
              {...buttonOption}
            />
          ) : inputValue ? (
            <NewIcon
              icon="xincircle-20"
              className="cursor-pointer"
              onClick={() => {
                setInputValue('');
                onInputChange('');
              }}
            />
          ) : placeholderIcon ? (
            <NewIcon
              icon={placeholderIcon}
              className="fill-new-gray-400 cursor-pointer"
              size={20}
            />
          ) : null}
        </article>
        {isError && (
          <p className="text-new-DTRed-400 text-new-Caption2-medium pt-6">{helperText}</p>
        )}
      </div>
    );
  },
);

export { Input, inputVariants };
